<script>
import JSZip from 'jszip';
import * as md5 from 'md5';

export default {
  name: 'GalleryDetail',
  props: {
    item: {
      type: Object,
      default: () => ({
        tokenId: '',
        attributes: [],
      }),
    },
    myTokenIds: {
      type: [Array, null],
      default: null,
    },
  },
  data: () => ({
    open: false,
    downloadOpen: false,
    delivery: 'https://delivery.endangeredlabs.io/download/',
    poses: [
      'fighter',
      'introvert',
      'legendary',
      'lover',
      'lunatic',
      'mastermind',
      'moderator',
      'mythic',
      'npc',
      'optimist',
      'performer',
      'poser',
      'prankster',
      'protector',
      'redeemer',
      'seeker',
      'thinker',
      'ultra',
      'warrior',
      'back',
      'left',
      'right',
      'kek',
      'salute',
      'gm',
      'smh',
      'autism',
      'pit-viper',
      'vr',
      'startle',
      'laser',
      'punch',
      'shook',
      'angel',
      'yes',
      'doom-scroll',
      'bamboominati',
      'balls_sunning',
      'body_slam',
      'conspiracy',
      'devil',
      'dont_care',
      'dreamer',
      'golem',
      'gotta_be',
      'let_him_cook',
      'listen_buddy',
      'malfunction',
      'no_pose',
      'one_does_not',
      'pushup',
      'sad',
      'sisyphus',
      'stomp',
      'test_tube',
      'tiger_squat',
      'trump_rebel',
      'yoga',
      'zombie',
      'aware',
      'alpachino',
      'basketbal_dribble',
      'basketbal_dunking',
      'corsswalk',
      'fbi_headphones',
      'gorge_bush',
      'hear_me_out',
      'joe_rogan_headphones',
      'matrix',
      'mib1',
      'mib2',
      'phone_call',
      'running',
      'sprey_paint',
      'wallstreet',
      'weightlift',
      //
      // cracked toujours en dernier
      'cracked',
    ],
    currentPose: null,
    loading: false,
    loadingFiles: 0,
    loadingDots: '',
    currentDotIndex: 0,
  }),
  computed: {
    hasPoses() {
      // const ordPose = this.item.attributes.find((attr) => attr.trait_type === 'Personality').value.toLowerCase();
      // return [...this.poses].find((p) => p === ordPose.toLowerCase()) !== undefined;
      return true;
    },
    inscriptionId() {
      return this.item.inscriptionId.slice(0, 6) + '...' + this.item.inscriptionId.slice(this.item.inscriptionId.length - 6, this.item.inscriptionId.length);
    },
    posesFiltered() {
      const ordPose = this.item.attributes.find((attr) => attr.trait_type === 'Personality').value.toLowerCase();

      return this.poses.filter((p) => p !== ordPose);
    },
    currentImageUrl() {
      if (!this.currentPose) {
        return 'https://delivery.endangeredlabs.io/download/public/fb/' + this.item?.tokenId + '.webp';
      }
      return 'https://delivery.endangeredlabs.io/download/public/poses_png/' + this.currentPose + '/' + this.item?.tokenId + '.png';
    },
    urlModelT() {
      if (!this.isMyToken) {
        return '';
      }
      const myToken = this.myTokenIds.find((t) => t.tokenId === Number(this.item.tokenId));
      if (!myToken) {
        return '';
      }
      return this.delivery + 'model-T/' + this.toUrl(myToken.tokenId + '_T.glb');
    },
    urlVRM() {
      if (!this.isMyToken) {
        return '';
      }
      const myToken = this.myTokenIds.find((t) => t.tokenId === Number(this.item.tokenId));
      if (!myToken) {
        return '';
      }
      return this.delivery + 'vrm/' + this.toUrl(myToken.tokenId + '_VRM.vrm');
    },
    urlFB() {
      return this.delivery + '/public/fb/' + this.item.tokenId + '.webp';
    },
    urlCurrentPose() {
      return this.delivery + '/public/poses_png/' + this.currentPose + '/' + this.item.tokenId + '.png';
    },
    urlPFP() {
      return this.delivery + 'pfp/' + this.item.download.pfp;
    },
    urlNoBG() {
      return this.delivery + 'nobg/' + this.item.download.nobg;
    },
    background() {
      return this.item.attributes.find((attr) => attr.trait_type === 'Background').value.toLowerCase();
    },
    isMyToken() {
      if (!this.myTokenIds || this.myTokenIds.length <= 0) {
        return false;
      }
      return this.myTokenIds.map((t) => t.tokenId).includes(Number(this.item.tokenId));
    },
  },
  async mounted() {
    await this.$nextTick();
    setTimeout(() => {
      this.open = true;
    }, 100);
  },
  methods: {
    close() {
      this.$emit('closing');
      this.open = false;
      setTimeout(() => {
        this.$emit('close');
      }, 500);
    },
    toUrl(file) {
      const ext = file.split('.')[1];
      const hash = md5(file);
      const newName = hash.substring(8, 16).toUpperCase();
      return newName + '.' + ext;
    },
    async clickedDownload(fileName, name) {
      try {
        const response = await fetch(fileName);
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({ err });
      }
    },
    animateLoading() {
      const dots = ['.', '..', '...']; // Tableau des différents états des points
      return setInterval(() => {
        this.currentDotIndex = (this.currentDotIndex + 1) % dots.length;
        this.loadingDots = dots[this.currentDotIndex];
      }, 500);
    },
    async clickedDownloadZip(poses, name) {
      try {
        const elements = [
          { url: this.urlPFP, name: `${this.item.tokenId}-pfp.png` },
          { url: this.urlFB, name: `${this.item.tokenId}-fullbody.webp` },
          { url: this.urlNoBG, name: `${this.item.tokenId}-nobackground.png` },
        ];
        const loadingInterval = this.animateLoading();
        this.loading = true;
        this.loadingFiles = elements.length + poses.length;
        var zip = new JSZip();
        for (const element of elements) {
          const file = await fetch(element.url).then((r) => r.blob());
          zip.file(element.name, file);
        }
        const posesFolder = zip.folder('poses');
        for (const pose of poses) {
          const url = this.urlPose(pose);
          const file = await fetch(url).then((r) => r.blob());
          posesFolder.file(`${pose}.png`, file);
        }
        const zipData = await zip.generateAsync({
          type: 'blob',
          streamFiles: true,
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(zipData);
        link.download = `${this.item.tokenId}-poses.zip`;
        link.click();
        clearInterval(loadingInterval);
        this.loading = false;
        this.loadingFiles = 0;
      } catch (err) {
        console.log({ err });
      }
    },
    urlPose(pose) {
      return this.delivery + '/public/poses_png/' + pose + '/' + this.item.tokenId + '.png';
    },
  },
};
</script>

<template>
  <div id="gallery-detail" @click.prevent.stop="close" :class="{ open }">
    <div class="content" @click.stop="downloadOpen = false">
      <div class="content-header">
        <div class="title">
          <h2>AILU #{{ item?.tokenId }}</h2>
        </div>
        <div>
          <div class="btn btn-secondary" @click.prevent.stop="close">
            Close
            <svg class="ico">
              <use xlink:href="#ico-cross"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="left" :class="{ hasPoses }">
          <div class="scrollable">
            <div class="inscription-id" style="margin-bottom: 10px">
              Inscription ID: {{ inscriptionId }}
              <div class="links">
                <a :href="'https://ordinals.com/inscription/' + item.inscriptionId" target="_blank" title="Ordinals.com">◉</a>
                <a :href="'https://magiceden.io/ordinals/item-details/' + item.inscriptionId" title="Magic Eden" target="_blank">
                  <svg class="ico">
                    <use xlink:href="#ico-magicEden"></use>
                  </svg>
                </a>
              </div>
            </div>
            <div class="attributes">
              <div v-for="attribute in item?.attributes" :key="attribute.trait_type" class="attribute">
                <div class="trait">{{ attribute.trait_type }}</div>
                <div class="value">{{ attribute.value }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right" :class="{ hasPoses }">
          <div class="image">
            <a class="btn-download" @click.prevent.stop="downloadOpen = !downloadOpen" title="Download files">
              <svg class="ico">
                <use xlink:href="#ico-download"></use>
              </svg>
            </a>
            <div class="download scroll-style" :class="{ downloadOpen }">
              <a class="btn-sub" :href="urlPFP" target="_blank" :download="item.tokenId + '_fb'" @click.prevent.stop.exact="clickedDownload(urlPFP, item.tokenId + '_pfp.png')">
                <svg class="ico">
                  <use xlink:href="#ico-download"></use>
                </svg>
                <span>{{ $t('downloadmodule.ordpfp') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.png') }}</span>
              </a>
              <a class="btn-sub" :href="urlFB" target="_blank" :download="item.tokenId + '_fb'" @click.prevent.stop.exact="clickedDownload(urlFB, item.tokenId + '_fb.webp')">
                <svg class="ico">
                  <use xlink:href="#ico-download"></use>
                </svg>
                <span>{{ $t('downloadmodule.ordfullbody') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.png') }}</span>
              </a>
              <a class="btn-sub" :href="urlNoBG" target="_blank" :download="item.tokenId + '_nobg'" @click.prevent.stop.exact="clickedDownload(urlNoBG, item.tokenId + '_nobg.png')">
                <svg class="ico">
                  <use xlink:href="#ico-download"></use>
                </svg>
                <span>{{ $t('downloadmodule.ordnobg') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.png') }}</span>
              </a>
              <a class="btn-sub" :href="urlModelT" target="_blank" :download="item.tokenId + '_glb'" v-if="isMyToken">
                <svg class="ico">
                  <use xlink:href="#ico-download"></use>
                </svg>
                <span>{{ $t('downloadmodule.modelT') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.glb') }}</span>
              </a>
              <a class="btn-sub" :href="urlVRM" target="_blank" :download="item.tokenId + '_fb'" v-if="isMyToken">
                <svg class="ico">
                  <use xlink:href="#ico-download"></use>
                </svg>
                <span>{{ $t('downloadmodule.vrm') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.vrm-file') }}</span>
              </a>
              <a v-if="currentPose" class="btn-sub" :href="urlPose(currentPose)" target="_blank" :download="item.tokenId + '_' + currentPose" @click.prevent.stop.exact="clickedDownload(urlPose(currentPose), item.tokenId + '_' + currentPose + '.webp')">
                <svg class="ico">
                  <use xlink:href="#ico-download"></use>
                </svg>
                <span>Current {{ $t('downloadmodule.currentPose') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.png') }}</span>
              </a>
              <a v-if="!loading" class="btn-sub" href="#" :download="item.tokenId + '_fb'" @click.prevent.stop.exact="clickedDownloadZip(poses, item.tokenId + '_' + pose + '.webp')">
                <svg class="ico">
                  <use xlink:href="#ico-download"></use>
                </svg>
                <span>{{ $t('downloadmodule.zip') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.zip-file') }}</span>
              </a>
              <div v-else class="items-empty" style="padding-top: 10px">
                <h2 class="title" style="font-size: inherit; line-height: 24px; margin-bottom: unset">Zipping {{ loadingFiles }} files{{ loadingDots }}</h2>
              </div>
              <hr class="download-hr" />
              <a class="btn-sub" :href="urlPose(pose)" target="_blank" :download="item.tokenId + '_' + currentPose" @click.prevent.stop.exact="clickedDownload(urlPose(pose), item.tokenId + '_' + pose + '.webp')" v-for="pose in poses" :key="pose">
                <svg class="ico">
                  <use xlink:href="#ico-download"></use>
                </svg>
                <span>{{ pose[0].toUpperCase() }}{{ pose.slice(1) }} {{ $t('downloadmodule.currentPose') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.png') }}</span>
              </a>
            </div>
            <img :src="currentImageUrl" alt="" :class="'bg-' + background" />
          </div>
        </div>
        <div class="poses">
          <div class="poses-wrapper scroll-style">
            <div class="thumbnail" @click="currentPose = null" :class="{ active: currentPose === null }">
              <img :src="'https://delivery.endangeredlabs.io/download/public/fb/' + item?.tokenId + '.webp'" alt="" :class="'bg-' + background" />
            </div>
            <div class="thumbnail" v-for="pose in posesFiltered" :key="pose" @click="currentPose = pose" :class="{ active: currentPose === pose }">
              <img :src="'https://delivery.endangeredlabs.io/download/public/poses/' + pose + '/' + item?.tokenId + '.webp'" alt="" :class="'bg-' + background" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#gallery-detail {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.5s;
  overflow: auto;
  backdrop-filter: blur(0px);

  @media (max-width: 900px) {
    align-items: flex-start;
    padding: 10px;
  }
  @media (max-width: 520px) {
    padding: 0px;
  }

  &.open {
    opacity: 1;
    backdrop-filter: blur(10px);

    .content {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .content {
    background: #0a1b1b;
    border: 1px solid #356666;
    border-radius: 6px;
    width: 100%;
    max-width: 1200px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: 0.5s;
    transform: translateY(30px);
    padding: 33px;

    @media (max-width: 1200px) {
      max-width: calc(100vw - 40px);
    }
    @media (max-width: 520px) {
      max-width: calc(100vw);
      border-radius: 0;
      padding: 20px;
      border-color: transparent;
    }

    .content-header {
      display: flex;
      justify-content: space-between;

      .btn-secondary {
        background: #0a1b1b;
        color: #39a0a0;
        border-color: #39a0a0;
        padding: 4px 9px;
        font-size: 13px;
        display: flex;
        align-items: center;
        gap: 5px;
        width: 100px;
        margin-left: auto;
        margin-bottom: 5px;

        svg {
          width: 10px;
          margin: 0;
          margin-left: auto;

          * {
            fill: #39a0a0;
          }
        }
      }
    }

    .row {
      @media (max-width: 900px) {
        flex-wrap: wrap;
      }
    }

    .left {
      width: 50%;

      &.hasPose {
        width: 45%;
      }

      @media (max-width: 900px) {
        width: 100%;
        order: 2;
      }
    }

    .title {
      margin-bottom: 20px;
      @media (max-width: 520px) {
        min-width: auto;
      }
    }

    .title h2 {
      font-size: 33px;
    }

    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-left: 33px;

      &.hasPose {
        width: 45%;
      }

      @media (max-width: 900px) {
        width: 100%;
        order: 1;
        margin-left: 0px;
        margin-bottom: 20px;
      }
    }

    .image {
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        border: 1px solid #39a0a0;
        background: #fff;
      }
    }

    .attributes {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .attribute,
    .inscription-id {
      padding: 10px 10px;
      background-color: #0a1b1b;
      border-radius: 3px;
      border: 1px solid #39a0a0;
      color: #39a0a0;
      text-transform: uppercase;
      transition-duration: 0.3s;
      overflow: hidden;

      div {
        display: block;
      }

      .trait {
        font-size: 10px;
        line-height: 12px;
      }
    }

    .poses {
      width: 10%;
      margin-left: 10px;
      min-height: 100%;
      position: relative;
      @media (max-width: 900px) {
        width: 100%;
        order: 1;
        margin-left: 0px;
        margin-bottom: 20px;
      }

      .poses-wrapper {
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-right: 10px;
        position: absolute;
        inset: 0;

        @media (max-width: 900px) {
          width: 100%;
          position: static;
          flex-direction: row;
          padding-right: 0px;
          overflow-y: hidden;
          overflow-x: auto;
          .thumbnail {
            min-width: 80px;
          }
        }
      }

      .thumbnail {
        cursor: pointer;

        img {
          margin-bottom: -4px;
          border: 1px solid #39a0a0;
          border-radius: 3px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: #fff;
        }

        &.active {
          img {
            border: 1px solid #00ffff;
          }
        }
      }
    }

    .inscription-id {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .links {
        display: flex;
        gap: 10px;
        align-items: center;

        a svg {
          fill: #00ffff;
        }
      }
    }
  }

  .btn-download {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: #0a1b1b;
    border-radius: 3px;
    border: 1px solid #39a0a0;
    fill: #39a0a0;
    padding: 10px 10px;
  }

  .download {
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #0a1b1b;
    border-radius: 3px;
    border: 1px solid #39a0a0;
    fill: #39a0a0;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;
    transform: translateY(-5px);
    z-index: 10;
    max-height: 230px;
    overflow-y: auto;

    &.downloadOpen {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
    }
    .download-hr {
      width: 100%;
      border-color: #39a0a0;
      margin-top: 20px;
    }

    .btn-sub {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-top: 10px;

      .sub-text {
        margin-left: auto;
        color: #39a0a0;
      }

      .ico {
        margin: 0;
        fill: #00ffff;
      }
    }
  }
}

img.bg-carbon {
  background: #000 !important;
}

img.bg-cloud {
  background: #fff !important;
}

img.bg-ff6000 {
  background: #ff6000 !important;
}
</style>
